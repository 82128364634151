<template>
  <section class="mx-3 mt-5">
    <div class="vip-container">
      <b-form class="justify-content-between" @submit.stop.prevent="() => {}">

        <b-form-group class="col-md-12"
          id="pick-up-from"
          :label="`${$t('footer.vip-reservation.pick-up-from')}:`"
          label-for="input-pick-up-from"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
        >
          <b-form-select
            required
            id="input-pick-up-from" v-model="pickUpFrom" :options="pickUpFromList"></b-form-select>
        </b-form-group>
        <b-form-group class="col-md-12"
          id="drop-to"
          :label="`${$t('footer.vip-reservation.drop-to')}:`"
          label-for="input-drop-to"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
        >
          <b-form-select
            required
            id="input-pick-up-from" v-model="dropTo" :options="pickUpFromList"></b-form-select>
        </b-form-group>

        <!-- <h3>{{ $t('footer.vip-reservation.services-required') }}</h3> -->
        <hr/>
        <b-form-row>
          <!-- <b-form-group id="input-group-services1" class="col-md-4">
            <b-form-checkbox-group stacked
              v-model="serviceChecked1"
              id="services-checked1"
            >
              <b-form-checkbox :value="service.value" v-for="(service, inx) in service1" :key="inx">{{ service.text }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group id="input-group-services2" class="col-md-4">
            <b-form-checkbox-group stacked
              v-model="serviceChecked2"
              id="services-checked2"
            >
              <b-form-checkbox :value="service.value" v-for="(service, inx) in service2" :key="inx">{{ service.text }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group> -->

          <b-form-group id="input-group-services3" class="col-md-12">
            <b-form-checkbox-group stacked
              v-model="serviceChecked3"
              id="services-checked3"
            >
              <b-form-checkbox :value="service.value" v-for="(service, inx) in service3" :key="inx">{{ service.text }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group class="col-md-12"
            id="passenger-number"
            :label="`${$t('footer.vip-reservation.number-passenger')}:`"
            label-for="input-passenger-number"
            :description="$t('footer.vip-reservation.number-passenger-description')"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <b-form-input
              id="input-passenger-number"
              v-model="passengerNumber"
              type="number"
              :placeholder="$t('footer.vip-reservation.number-passenger')"
              required
              min="1"
            ></b-form-input>
          </b-form-group>
        </b-form-row>

        <!-- <h3>{{$t('footer.vip-reservation.name')}}</h3> -->
        <hr/>
        <b-form-row>
          <b-form-group class="col-md-12"
            id="input-group-first-name"
            :label="$t('footer.vip-reservation.first-name')"
            label-for="input-first-name"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <b-form-input
              id="input-first-name"
              v-model="firstName"
              type="text"
              :placeholder="$t('footer.vip-reservation.enter-first-name')"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-12"
            id="input-group-last-name"
            :label="$t('footer.vip-reservation.last-name')"
            label-for="input-last-name"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <b-form-input
              id="input-last-name"
              v-model="lastName"
              type="text"
              :placeholder="$t('footer.vip-reservation.enter-last-name')"
              required
            ></b-form-input>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group class="col-md-12"
            id="email"
            :label="$t('footer.vip-reservation.email')"
            label-for="input-email"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <b-form-input
              id="input-email"
              v-model="email"
              type="email"
              :placeholder="$t('footer.vip-reservation.enter-email')"
              required
            ></b-form-input>
          </b-form-group>

          <div class="phone-group row col-sm-12 col-ig-12 pr-0 mb-3">
            <h6 class="phone-label col-sm-3">{{ $t('footer.vip-reservation.phone') }}</h6>
            <vue-phone-number-input
            required
            v-model="phone"
            default-country-code="IL"
            class="col-sm-9 pr-0 mr-0"
            @update="mobileNumberPayload"
            />
          </div>

          <!-- <b-form-group class="col-md-12"
            id="phone"
            :label="$t('footer.vip-reservation.phone')"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="phone"
              type="number"
              :placeholder="$t('footer.vip-reservation.enter-phone-number')"
              required
            ></b-form-input>
          </b-form-group> -->
        </b-form-row>

        <b-form-row>
          <b-form-group class="col-md-12"
            id="arrivalDate"
            :label="$t('footer.vip-reservation.date-arrival')"
            label-for="input-arrival-date"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <date-picker
              id="input-arrival-date"
              required
              v-model="arrivalDate"
              :config="arrivalDateFormat"
              :placeholder="$t('footer.vip-reservation.arrival-date')"
            />
          </b-form-group>

          <b-form-group class="col-md-12"
            id="departureDate"
            :label="$t('footer.vip-reservation.date-departure')"
            label-for="input-departure-date"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <date-picker
              id="input-departure-date"
              required
              v-model="departureDate"
              :config="departureDateFormat"
              :placeholder="$t('footer.vip-reservation.departure-date')"
            />
          </b-form-group>

        </b-form-row>

        <b-form-row>
          <b-form-group class="col-md-12"
            id="flightNumber"
            :label="`${$t('footer.vip-reservation.flight-number')}:`"
            label-for="input-flight-number"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <b-form-input
              id="input-flight-number"
              v-model="flightNumber"
              type="text"
              :placeholder="$t('footer.vip-reservation.flight-number')"
              required
            ></b-form-input>
          </b-form-group>

        </b-form-row>

        <hr/>
        <b-form-group
          :label="`${$t('footer.vip-reservation.pick-up-address')}:`"
          label-for="input-pick-up-address"
          class="mt-5"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
        >
          <b-form-textarea
            id="input-pick-up-address"
            v-model="pickUpAddress"
            :placeholder="$t('footer.vip-reservation.pick-up-address')"
            rows="2"
            max-rows="4"
          ></b-form-textarea>

        </b-form-group>
        <b-form-group
          :label="`${$t('footer.vip-reservation.drop-address')}:`"
          label-for="input-drop-address"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
        >
          <b-form-textarea
            id="input-drop-address"
            v-model="dropAddress"
            :placeholder="$t('footer.vip-reservation.drop-address')"
            rows="2"
            max-rows="4"
          ></b-form-textarea>

        </b-form-group>
        <b-form-group
          :label="`${$t('footer.vip-reservation.remarks')}:`"
          label-for="input-remarks"
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm="8"
          content-cols-lg="9"
          :description="$t('footer.vip-reservation.max-number-limit-remark')"
        >
          <b-form-textarea
            id="input-remarks"
            v-model="remarks"
            :placeholder="$t('footer.vip-reservation.enter-remarks')"
            rows="1"
            max-rows="1"
            @keypress="updateRemarks"
          ></b-form-textarea>

        </b-form-group>

        <b-button type="submit" variant="primary" class="mt-5" @click="submit" :disabled="isSending">
          <b-spinner small v-if="isSending"></b-spinner>
          {{ $t('footer.vip-reservation.submit') }}
        </b-button>

        <b-alert :variant="isSuccess ? 'success': 'danger'" :show="true" class="my-5" :class="{'text-right': lang === 'he'}" v-if="responseArrival">
          <p>{{ isSuccess ? 'Success !': errorMsg }}</p>
        </b-alert>

      </b-form>
    </div>
  </section>

</template>

<script>
import { BForm, BFormGroup, BFormInput, BFormCheckbox, BFormSelect,
  BFormRow, BFormCheckboxGroup, BFormTextarea, BSpinner, BAlert } from 'bootstrap-vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { mapGetters } from 'vuex';

export default {
  name: 'VipReservation',
  components: {
    datePicker,
    VuePhoneNumberInput,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BSpinner,
    BAlert,
    BFormSelect,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      emailResult: 'GET_VIP_RESERVATION_EMAIL_RESULT',
    }),
    isValidation() {
      return this.pickUpFrom && this.dropTo && this.firstName && this.lastName && this.email && this.phone && this.arrivalDate && this.departureDate && this.flightNumber && this.passengerNumber !== 0;
    },
    isSending() {
      return this.emailResult === 'sending...';
    },
    responseArrival() {
      return !this.isSending && this.emailResult && typeof (this.emailResult) === 'object';
    },
    isSuccess() {
      const { responseArrival, emailResult } = this;
      return (responseArrival && emailResult.data);
    },
    errorMsg() {
      const { responseArrival, emailResult } = this;
      if (responseArrival && !emailResult.error) return '';
      return `${emailResult?.error?.message || ''} \n error code: ${emailResult?.error?.code || ''}`;
    },
    arrivalDateFormat() {
      return {
        format: 'DD/MM/YYYY',
        minDate: new Date(),
        defaultDate: new Date(),
      };
    },
    departureDateFormat() {
      const { arrivalDate } = this;
      const min = (arrivalDate) ? new Date(this.formatDateString(arrivalDate)) : new Date();
      return {
        format: 'DD/MM/YYYY',
        minDate: min,
        defaultDate: min,
      };
    },
  },
  watch: {
    arrivalDate() {
      const arrival = new Date(this.formatDateString(this.arrivalDate)),
        departure = new Date(this.formatDateString(this.departureDate));

      if (arrival > departure) {
        this.departureDate = this.arrivalDate;
      }
    },
  },
  data() {
    return {
      pickUpFromList: [
        'Ben Gurion airport terminal 3',
        'Ben Gurion airport terminal 1',
        'Ramon airport',
        'Tel Aviv ',
        'Bat Yam',
        'Herzliya',
        'Netanya',
        'Jerusalem',
        'Ashkelon',
        'Haifa',
        'Tiberias',
        'Ashdod',
        'Dead Sea',
        'Eilat',
      ],
      pickUpFrom: '',
      dropTo: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phoneNumberWithCountry: '',
      arrivalDate: '',
      departureDate: '',
      flightNumber: '',
      passengerNumber: 1,
      serviceChecked1: [],
      serviceChecked2: [],
      serviceChecked3: [],
      // service1: [
      //   { value: 'vipMeetAssistArrival', text: this.$t('footer.vip-reservation.vip-meet-assist-arrival') },
      //   { value: 'vipMeetAssistDeparture', text: this.$t('footer.vip-reservation.vip-meet-assist-departure') },
      //   { value: 'privateTransportationTo', text: this.$t('footer.vip-reservation.private-transportation-to') },
      //   { value: 'privateTransportationFrom', text: this.$t('footer.vip-reservation.private-transportation-from') },
      //   { value: 'tours', text: this.$t('footer.vip-reservation.tours') },
      // ],
      // service2: [
      //   { value: 'pssgr1-3SkodaSuperb', text: this.$t('footer.vip-reservation.passengers-skoda-superb') },
      //   { value: 'pssgr4-6Hyundai', text: this.$t('footer.vip-reservation.passengers-hyundai-i800') },
      //   { value: 'pssgr1-6MercedesV', text: this.$t('footer.vip-reservation.passengers-mercedes-v') },
      //   { value: 'pssgr2MercedesE', text: this.$t('footer.vip-reservation.passengers-mercedes-e') },
      // ],
      service3: [
        { value: 'vipArrivalSilver', text: this.$t('footer.vip-reservation.vip-arrival-silver-service') },
        { value: 'vipArrivalPlatinum', text: this.$t('footer.vip-reservation.vip-arrival-platinum-service') },
        { value: 'vipDepartureBronze', text: this.$t('footer.vip-reservation.vip-departure-bronze-service') },
        { value: 'vipDepartureSilver', text: this.$t('footer.vip-reservation.vip-departure-silver-service') },
        { value: 'vipDeparturePlatinum', text: this.$t('footer.vip-reservation.vip-departure-platinum-service') },
      ],
      pickUpAddress: '',
      dropAddress: '',
      remarks: '',
      maxRemarksCharacters: 200,
    };
  },
  mounted() {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;
    this.arrivalDate = `${day}/${month}/${year}`;
    this.departureDate = `${day}/${month}/${year}`;
  },
  methods: {
    updateRemarks() {
      if (this.remarks.length > this.maxRemarksCharacters) this.remarks = this.remarks.slice(0, this.maxRemarksCharacters);
    },
    async submit() {
      if (!this.isValidation) return;
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phoneNumberWithCountry,
        dateOfArrival: this.arrivalDate,
        dateOfDeparture: this.departureDate,
        flightNumber: this.flightNumber,
        numberOfPassengers: +this.passengerNumber,
        serviceRequired: {},
        remarks: this.remarks,

        pickUpFrom: this.pickUpFrom,
        dropTo: this.dropTo,
        pickUpAddress: this.pickUpAddress,
        dropAddress: this.dropAddress,
      };
      // this.service1.forEach((service) => {
      //   body.serviceRequired[service.value] = this.serviceChecked1.includes(service.value);
      // });
      // this.service2.forEach((service) => {
      //   body.serviceRequired[service.value] = this.serviceChecked2.includes(service.value);
      // });
      this.service3.forEach((service) => {
        body.serviceRequired[service.value] = this.serviceChecked3.includes(service.value);
      });

      await this.$store.dispatch('SEND_VIP_RESERVATION_EMAIL', body);
    },
    mobileNumberPayload(payload) {
      this.phoneNumberWithCountry = payload.formattedNumber;
    },
    formatDateString(oDate) {
      if (!oDate) return '';
      const arrDateStr = oDate.split('/');
      return `${arrDateStr[1]}/${arrDateStr[0]}/${arrDateStr[2]}`;
    },
  },
};
</script>

<style>
  .vip-container{
    max-width: 700px;
    margin: auto;
    font-size: 1.1rem;
  }
  .vip-container input, .vip-container select, .vip-container .country-selector__input, .vip-container .input-tel__input, .vue-phone-number-input {
    height: 3rem;
    font-size: 1.2rem;
  }
  .vip-container .form-group label, .phone-group .phone-label {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .vip-container textarea {
    resize: none;
  }
</style>
